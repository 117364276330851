import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {SessionService} from "../../services/session.service";
import {User} from "../../models/user";
import {PreloaderService} from "../../services/preloader.service";
import {MatDrawer} from "@angular/material/sidenav";
import {Navigation} from "../../models/navigation";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  @ViewChild(MatDrawer) drawer!: MatDrawer;
  isLoading: boolean = false;
  isDisplay: boolean = true;
  size: number = 0;
  user!: User;
  minWidth: number = 899;

  constructor(
    private router: Router,
    private session: SessionService,
    private preloader: PreloaderService
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize($event: any): void {
    this.toggleBySize($event.target.innerWidth);
  }

  ngOnInit(): void {
    this.toggleBySize(window.screen.width);
    this.session.currentSession.subscribe({
      next: (success) => {
        if (success) {
          this.user = success.user;
        }
      }
    });
    let self = this;
    this.preloader.isLoading.subscribe((success) => {
      setTimeout(function () {
        self.isLoading = success;
      }, 200);
    });
  }

  toggleBySize(size: number) {
    this.size = size;
    this.isDisplay = size > this.minWidth;
  }

  logout(): void {
    this.session.logout();
    this.router.navigate(['/auth/login']);
  }

  toggleFullscreen(): void {
    const document: any = window.document;
    const documentElement: any = document.documentElement;

    const requestFullscreen =
      documentElement.requestFullscreen ||
      documentElement.mozRequestFullScreen ||
      documentElement.webkitRequestFullScreen ||
      documentElement.msRequestFullscreen;

    const exitFullscreen =
      document.exitFullscreen ||
      document.mozCancelFullScreen ||
      document.webkitExitFullscreen ||
      document.msExitFullscreen;

    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
      requestFullscreen.call(documentElement);
    } else {
      exitFullscreen.call(document);
    }
  }

  getViewUser(): string {
    return `/users/profile`;
  }

  changeNavigation($event: Navigation) {
    if (this.size < this.minWidth) {
      this.drawer.toggle();
    }
  }
}
