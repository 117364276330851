import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {
  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _count: number = 0;

  constructor() {
  }

  get isLoading(): BehaviorSubject<boolean> {
    return this._isLoading;
  }

  show() {
    this._count++;
    this._isLoading.next(true);
  }

  hide() {
    this._count--;
    if (this._count <= 0) {
      this._count = 0;
      this._isLoading.next(false);
    }
  }
}
