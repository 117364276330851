import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Navigation} from "../../models/navigation";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {NavigationGroup} from "../../models/navigation-group";
import {AuthService} from "../../services/auth.service";
import {NavigationService} from "../../services/navigation.service";
import {App} from "../../models/app";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Output() selectItem: EventEmitter<Navigation> = new EventEmitter<Navigation>();

  groups: NavigationGroup[] = [
    {
      label: 'Inicio',
      items: [
        {
          label: 'Inicio',
          icon: 'i-Home1',
          link: '/home'
        },
      ]
    },
    {
      label: 'Servicios',
      items: []
    },
    {
      label: 'Mi Cuenta',
      items: [
        {
          label: 'Servicios',
          icon: 'i-Computer-Secure',
          link: '/apps'
        },
        {
          label: 'Soporte',
          icon: 'i-Support',
          link: '/tickets'
        },
        {
          label: 'Facturación',
          icon: 'i-Letter-Open',
          link: '/billing'
        },
      ]
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: AuthService,
    private navigation: NavigationService
  ) {
  }

  ngOnInit(): void {
    this.validateRoute();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.validateRoute();
      }
    });
    this.getApps();
    this.navigation.update.subscribe({
      next: (success) => {
        if (success) {
          this.getApps();
        }
      }
    })
  }

  getApps(): void {
    this.api.getActiveApps().subscribe({
      next: (success) => {
        let apps: Map<string, App> = new Map<string, App>();
        let items: Navigation[] = [];
        success.map(item => {
          items.push({
            link: item.appsId.appUrl,
            label: item.appsId.name,
            icon: item.appsId.icon
          });
          apps.set(item.appsId.code, item.appsId);
        });
        this.navigation.apps = apps;
        this.groups[1].items = items;
        this.validateRoute();
      }
    });
  }

  validateRoute(): void {
    this.groups.map((group => {
      group.items.map(value => {
        value.active = this.router.url.startsWith(value.link)
      });
    }))
  }
}
