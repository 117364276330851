import {UrlService} from "./url.service";
import {HttpClient, HttpParams} from "@angular/common/http";

export abstract class BaseApi {

  protected constructor(protected url: UrlService, protected http: HttpClient) {
  }

  getOnlyParams(params: Map<string, string>): HttpParams {
    let httpParams = new HttpParams();
    // tslint:disable-next-line:only-arrow-functions
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    params.forEach(function (value, key) {
      httpParams = httpParams.set(key, value);
    });
    return httpParams;
  }

}
