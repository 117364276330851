<div *ngFor="let group of groups">
  <strong class="ml-2" *ngIf="group.items&&group.items.length>0">{{ group.label }}</strong>
  <mat-nav-list *ngIf="group.items&&group.items.length>0">
    <mat-list-item *ngFor="let item of group.items" [routerLink]="item.link" [ngClass]="item.active?'active':''"
                   (click)="selectItem.emit(item)">
      <div class="row">
        <div class="col-3 icon">
          <i class="i-Dashboard" [ngClass]="item.icon"></i>
        </div>
        <div class="col-9">
          <span class="nav-label">{{ item.label }}</span>
        </div>
      </div>
    </mat-list-item>
  </mat-nav-list>
</div>
