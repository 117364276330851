export const environment = {
    production: true,
    apiUrl: 'https://api.login.sandbox.crecerix.com',
    storageUrl: 'https://api.sandbox.crecerix.com/storage',
    portalUrl: 'https://api.portal.crecerix.com',
    checkoutUrl: 'https://app.crecerix.com/pagos',
    proxyUrl: 'https://api.sandbox.crecerix.com',
    sessionKey: 'AppSessionKeyLoginProduction',
    externalUrl: true,
  };
  