import {Injectable} from '@angular/core';
import {BaseApi} from "./base-api";
import {UrlService} from "./url.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Login} from "../models/login";
import {App} from "../models/app";
import {AccountApp} from "../models/account-app";
import {Validation} from "../models/validation";
import {Account} from "../models/account";
import {User} from "../models/user";
import {Billing} from "../models/billing";
import {DocumentType} from "./document-type";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApi {

  constructor(url: UrlService, http: HttpClient) {
    super(url, http);
  }

  register(data: any): Observable<Login> {
    let url = `${this.url.url}/login/register`;
    return this.http.post<Login>(url, data);
  }

  login(data: any): Observable<Login> {
    let url = `${this.url.url}/login/signin`;
    return this.http.post<Login>(url, data);
  }

  forgot(data: any): Observable<any> {
    let url = `${this.url.url}/login/forgot`;
    return this.http.post<any>(url, data);
  }

  reset(data: any): Observable<Login> {
    let url = `${this.url.url}/login/reset`;
    return this.http.post<Login>(url, data);
  }


  /** Apps */
  apps(): Observable<App[]> {
    const url = `${this.url.url}/apps/active`;
    return this.http.get<App[]>(url);
  }

  viewApp(app: string): Observable<App> {
    const url = `${this.url.url}/apps/${app}`;
    return this.http.get<App>(url);
  }

  /** Account apps */

  getActiveApps(): Observable<AccountApp[]> {
    const url = `${this.url.url}/account/apps`;
    return this.http.get<AccountApp[]>(url, {});
  }

  validateActiveApp(app: string): Observable<Validation> {
    const url = `${this.url.url}/account/apps/${app}/validate`;
    return this.http.get<Validation>(url);
  }

  activateApp(app: string): Observable<AccountApp> {
    const url = `${this.url.url}/account/apps/${app}`;
    return this.http.post<AccountApp>(url, {});
  }

  inactiveApp(app: string): Observable<AccountApp> {
    const url = `${this.url.url}/account/apps/${app}`;
    return this.http.put<AccountApp>(url, {});
  }

  enableInApp(appUrl: string): Observable<Account> {
    const url = `${appUrl}/accounts`;
    return this.http.get<Account>(url);
  }

  authenticated(): Observable<User> {
    const url = `${this.url.url}/users/authenticated`;
    return this.http.get<User>(url);
  }

  updateUser(user: string, data: any): Observable<User> {
    const url = `${this.url.url}/users/${user}`;
    return this.http.post<User>(url, data);
  }

  billing(): Observable<Billing> {
    const url = `${this.url.url}/billing`;
    return this.http.get<Billing>(url);
  }

  saveBilling(data: any): Observable<Billing> {
    const url = `${this.url.url}/billing`;
    return this.http.post<Billing>(url, data);
  }

  documentTypes(): Observable<DocumentType[]> {
    const url = `${this.url.url}/billing/types/documents`;
    return this.http.get<DocumentType[]>(url);
  }
}
