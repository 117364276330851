import {Injectable} from '@angular/core';
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() {
  }

  get url(): string {
    if (environment.externalUrl) {
      return environment.apiUrl;
    } else {
      return location.origin;
    }
  }

  get whatsapp(): string {
    return `${environment.proxyUrl}/whatsapp`;
  }

  get templates(): string {
    return `${environment.proxyUrl}/templates`;
  }

  get storage(): string {
    return `${environment.proxyUrl}/storage`;
  }

  get portal(): string {
    return environment.proxyUrl;
  }

  get checkout(): string {
    return environment.checkoutUrl;
  }

  get contacts(): string {
    return `${environment.proxyUrl}/contacts`;
  }
}
