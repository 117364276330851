import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {Login} from "../models/login";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  itemName: string = environment.sessionKey;
  public currentSession: Observable<Login | null>;
  private currentSessionSubject: BehaviorSubject<Login | null>;

  constructor() {
    let value = localStorage.getItem(this.itemName);
    let converted = null;
    if (value) {
      converted = JSON.parse(value);
    }
    this.currentSessionSubject = new BehaviorSubject<Login | null>(converted ?? null);
    this.currentSession = this.currentSessionSubject.asObservable();
  }

  get token(): Login | null {
    let value = localStorage.getItem(this.itemName);
    let converted = null;
    if (value) {
      converted = JSON.parse(value);
    }
    return <Login | null>converted;
  }

  set token(session: Login | null) {
    localStorage.setItem(this.itemName, JSON.stringify(session));
    this.currentSessionSubject.next(session);
  }

  logout(): void {
    localStorage.removeItem(this.itemName);
    this.currentSessionSubject.next(null);
  }
}
