import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {finalize, Observable} from 'rxjs';
import {PreloaderService} from "../services/preloader.service";

@Injectable()
export class PreloaderInterceptor implements HttpInterceptor {

  constructor(private preloader: PreloaderService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.preloader.show();
    return next.handle(request).pipe(finalize(() => this.preloader.hide()),);
  }
}
