import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {App} from "../models/app";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public update: Observable<App>;
  private updateSubject: BehaviorSubject<App>;
  private activeApps: Map<string, App> = new Map<string, App>();

  constructor() {
    this.updateSubject = new BehaviorSubject<any>(null);
    this.update = this.updateSubject.asObservable();
  }

  updateItem(app: App): void {
    this.updateSubject.next(app);
  }

  set apps(apps: Map<string, App>) {
    this.activeApps = apps;
  }

  get apps(): Map<string, App> {
    return this.activeApps;
  }

}
