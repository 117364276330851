<div class="overlay fixed-top" *ngIf="isDisplay">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>
<mat-drawer-container class="example-container">
  <mat-drawer #drawer [mode]="size<minWidth?'over':'side'" [opened]="isDisplay" class="p-2 mat-drawer bg-purple-600">
    <div class="row">
      <div class="col-3">
        <img src="/assets/images/crecerix-icon.png" alt="" class="logo mt-2 mb-2 "/>
      </div>
      <div class="col-9 crecerix">
        <span class="mt-4">Crecerix</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 navigation">
        <app-navigation (selectItem)="changeNavigation($event)"></app-navigation>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <mat-toolbar class="bg-white">
      <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="spacer"></span>
      <button mat-icon-button (click)="toggleFullscreen()">
        <i class="i-Full-Screen text-2xl"></i>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <i class="i-Boy text-2xl"></i>
      </button>
      <mat-menu #menu="matMenu">
        <a [routerLink]="getViewUser()" mat-menu-item *ngIf="user">
          <span>{{ user.firstName }}</span><br>
          <small>{{ user.email }}</small>
        </a>
        <a routerLink="/account/billing" mat-menu-item *ngIf="user&&user.primaryUser">
          <mat-icon>payments</mat-icon>
          <span>Información de facturación</span>
        </a>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Salir</span>
        </button>
      </mat-menu>
    </mat-toolbar>
    <div class="content mt-16">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
