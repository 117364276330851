import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from "../components/layout/layout.component";
import {canActivateChild} from "../guards/auth.guard";

const routes: Routes = [
  {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
      {path: 'whatsapp', loadChildren: () => import('./whatsapp/whatsapp.module').then(m => m.WhatsappModule)},
      {path: 'storage', loadChildren: () => import('./storage/storage.module').then(m => m.StorageModule)},
      {path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule)},
      {path: 'billing', loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule)},
      {path: 'tickets', loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule)},
      {path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule)},
      {path: 'contacts', loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)},
      {path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)},
      {path: '**', redirectTo: '/home'}
    ],
    canActivate: [canActivateChild]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
